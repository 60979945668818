<template>
  <div class="card__box">
    <div class="card__wrapper" :class="{'card__wrapper--active': isOurs}">
      <div class="card__top">
        <div class="card__row">
          <div class="card__price">$ {{ buyer.maxPrice }}</div>
          <div class="card__cell">
            <div class="card__button button__icon button__icon--border" @click.stop.prevent>
              <Multiselect
                class="mSelect-dropdown"
                :options="list"
                :searchable="false"
                :show-labels="false"
                :reset-after="true"
                :value="item"
                placeholder=""
                open-direction="bottom"
                @select="actions"
              />
            </div>
            <div v-if="selectionActive" class="card__button button__icon button__icon--border" @click.stop.prevent="select">
              <i :class="{'ri-checkbox-fill': buyer.isSelected, 'ri-checkbox-blank-line': !buyer.isSelected}"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="card__middle">
        <div v-if="!showSettlements" class="card__field card__field--dark">
          <div class="card__district">
            <i class="i-location i-location--blue"></i>
            <span class="card__span">{{ settlements }}</span>
          </div>
        </div>
        <div v-if="districtsName" class="card__field card__field--dark">
          <div class="card__districts">
            <span class="card__span">{{ districtsName }}</span>
          </div>
        </div>
        <div class="card__row card__tags">
          <div class="card__tag card__tag--yellow">
            <span class="card__span">{{ buyer.motivation }}</span>
          </div>
          <div class="card__tag card__tag--green">
            <span class="card__span">{{ buyerStatus }}</span>
          </div>
          <div class="card__tag card__tag--red">
            <span class="card__span">{{ buyer.contract }}</span>
          </div>
        </div>
      </div>
      <div class="card__bottom">
        <div class="card__id">ID {{ buyer.id }}</div>
        <Relation v-for="(rel, i) in buyer.relations" :relation="rel" type="card-tile" :key="i"/>
        <div class="card__icoWrapper">
          <div class="card__agent" :class="{'card__agent--blocked': buyer.isResponsibleBlocked}">
            <i class="i-employees card__agent-icon"></i>
            <span class="card__agent-text">{{ buyer.responsibleName }}</span>
          </div>
        </div>
      </div>
      <div class="card__fields">
        <div v-if="type === 'house'" class="card__field">
          <div class="card__text">Розташування</div>
          <div class="card__text">{{ buyer.range }}</div>
        </div>
        <div class="card__field">
          <div class="card__text">К-сть кімнат</div>
          <div class="card__text">{{ buyer.noOfRooms }}</div>
        </div>
        <div v-if="type !== 'commerce'" class="card__field">
          <div class="card__text">Вид</div>
          <div class="card__text">
            {{ material }}
          </div>
        </div>
        <div class="card__field">
          <div class="card__text">
            <span v-if="type !== 'commerce'">Тип</span>
            <span v-else>Призначення</span>
          </div>
          <div class="card__text">
            {{ buildingType }}
          </div>
        </div>
        <div v-if="type !== 'apartment'" class="card__field">
          <div class="card__text">Площа м²</div>
          <div v-if="type === 'house'" class="card__text">{{ buyer.squareLand }}/{{ buyer.squareTotal }}</div>
          <div v-else class="card__text">{{ buyer.squareTotal }}</div>
        </div>
        <div v-if="type === 'apartment'" class="card__field">
          <div class="card__text">Поверх</div>
          <div class="card__text">{{ floor }}</div>
        </div>
        <div class="card__field card__field--blue">
          <div class="card__text">Причина видалення</div>
          <div class="card__text">{{ archivedReason }}</div>
        </div>
        <div class="card__field card__field--blue">
          <div class="card__text">Видалено до</div>
          <div class="card__text">
            <i class="i-calendar"></i>
            <div v-if="buyer.archivedTill">{{ buyer.archivedTill | date }}</div>
          </div>
        </div>
        <div class="card__field card__field--blue">
          <div class="card__text">Дата видалення</div>
          <div class="card__text">
            <i class="i-calendar"></i>
            {{ buyer.archivedAt | date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import list from '@/filters/filtersList'
import ModalArchive from '@/components/ModalArchive'
import Relation from '@/components/Relation'

export default {
  name: 'ArchiveCardBuyer',
  props: ['buyer'],
  computed: {
    selectionActive() {
      switch (this.buyer.lookingFor) {
        case 'apartment':
          return this.$store.getters['archive/buyers/apartments/selectionActive']
        case 'house':
          return this.$store.getters['archive/buyers/houses/selectionActive']
        case 'commerce':
          return this.$store.getters['archive/buyers/commerce/selectionActive']
        default:
          return false
      }
    }
  },
  components: {
    Multiselect,
    Relation,
  },
  data: () => ({
    list: ['Відновити'],
    item: '',
    material: null,
    buildingType: null,
    floor: null,
    buyerStatus: null,
    userRole: null,
    archivedReason: null,
    districtsName: null,
    type: null,
    showSettlements: true,
    isOurs: false
  }),
  created() {
    const type = (this.type = this.$route.meta.class)

    const sourceBuyer = this.$store.getters.constants.PROPERTY_SOURCES[2]?.children.map(p => p.id)
    this.isOurs = !sourceBuyer.some(prop => prop === this.buyer.source)

    switch (type) {
      case 'apartment':
        this.floor = this.buyer.floor.join(', ')
        this.material = this.buyer.material.map(m => list.apartmentMaterial.find(mat => mat.id === m)?.name).join(', ')
        this.buildingType = this.buyer.buildingType
          .map(type => list.apartmentType.find(t => t.id === type)?.name)
          .join(', ')

        break
      case 'house':
        this.material = this.buyer.material.map(m => list.houseMaterial.find(mat => mat.id === m)?.name).join(', ')
        this.buildingType = this.buyer.buildingType.map(type => list.houseType.find(t => t.id === type)?.name).join(', ')

        break
      case 'commerce':
        this.buildingType = this.buyer.buildingType
          .map(type => list.commerceType.find(t => t.id === type)?.name)
          .join(', ')
        break
    }
    this.showSettlements = this.buyer.settlement.includes('Рівне') && this.buyer.settlement.length === 1
    this.buyerStatus = list.status.find(s => s.id === this.buyer.buyerStatus).name
    this.districtsName = this.buyer.districtID.map(d => d.name).join(', ')
    this.settlements = this.buyer.settlement.join(', ')
    this.archivedReason = list.ARCHIVED_REASONS.find(s => s.id === this.buyer.archivedReason).name
    this.userRole = this.$store.getters.user.role
    if (this.userRole === 4) {
      this.list.push('Редагувати', 'Видалити')
    } else if (this.buyer.responsibleID === this.$store.getters.user.id) {
      this.list.push('Редагувати')
    }
  },
  methods: {
    async actions(option) {
      const id = this.buyer.id
      if (option === 'Видалити') {
        try {
          switch (this.buyer.lookingFor) {
            case 'apartment':
              await this.$store.dispatch('archive/buyers/apartments/delete', id)
              break
            case 'house':
              await this.$store.dispatch('archive/buyers/houses/delete', id)
              break
            case 'commerce':
              await this.$store.dispatch('archive/buyers/commerce/delete', id)
              break
          }
        } catch {}
      } else if (option === 'Відновити') {
        try {
          switch (this.buyer.lookingFor) {
            case 'apartment':
              await this.$store.dispatch('archive/buyers/apartments/restore', id)
              break
            case 'house':
              await this.$store.dispatch('archive/buyers/houses/restore', id)
              break
            case 'commerce':
              await this.$store.dispatch('archive/buyers/commerce/restore', id)
              break
          }
        } catch {}
      } else if (option === 'Редагувати') {
        this.$modal.display(
          ModalArchive,
          {info: this.buyer, entity: 'buyers', action: 'update'},
          {
            transition: 'none',
            classes: 'modalArchive',
            width: '500px',
            height: '350px',
            name: 'ModalArchive'
          }
        )
      }
    },
    async select() {
      const id = this.buyer.id
      let path = ''
      switch (this.type) {
        case 'apartment':
          path = 'archive/buyers/apartments/selectBuyer'
          break
        case 'house':
          path = 'archive/buyers/houses/selectBuyer'
          break
        case 'commerce':
          path = 'archive/buyers/commerce/selectBuyer'
          break
      }
      this.$store.commit(path, {id, isSelected: !this.buyer.isSelected})
    },
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
.card__button {
  .ri-checkbox-fill, .ri-checkbox-blank-line {
    font-size: 20px;
  }
}
</style>